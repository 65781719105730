import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import TextPageLayout from "../components/text-page-layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TextPageLayout title="Links" mdxType="TextPageLayout">
      <h1>{`Links`}</h1>
      <p>{`Here are some links to my favorite things.`}</p>
      <h2>{`Philosophy`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Zen_and_the_Art_of_Motorcycle_Maintenance"
          }}>{`Zen and the Art of Motorcycle Maintenance (Pirsig 1974)`}</a>{`: An ingenious exploration of the dichotomy between facts and values, science and religion, technology and art, function and form, thinking and feeling, classical rationality and romantic intuition. As his motorcycle twists and turns through the American midwest, Pirsig gives his readers a tour de force through some of the most fascinating insights from philosophy (Plato, Aristotle, Kant), mathematics (Euclid, Poincaré, non-Euclidean geometries), logic (Gödel's incompleteness theorem), science (Copernicus, Newton, the nature of "truth" in science), and spirituality (Taoism, Zen), sewing them, thread by thread, into a novel Metaphysics of Quality that valiantly seeks to unite rationality and intuition. You will need to read this several times before you finally grasp the significance of his ideas—and when you do, your whole world will be blown apart. Also listen to his `}<a parentName="li" {...{
            "href": "https://youtu.be/RULOIr4MJII"
          }}>{`NPR interview`}</a>{` where he discusses how he wrote the book.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Brave_New_World"
          }}>{`Brave New World (Huxley 1932)`}</a>{`: A realistic vision of a dystopia in the near-future. Although this book tends to be less popular than George Orwell's `}<em parentName="li">{`1984`}</em>{`, in my opinion it presents a much more likely scenario. Whereas `}<em parentName="li">{`1984`}</em>{` focuses on state control by fear, `}<em parentName="li">{`Brave New World`}</em>{` shows that a far more efficient and effective method of control would instead be by `}<em parentName="li">{`pleasure`}</em>{`, with a state manipulating citizens into enslaving themselves to endless hedonism by their own free will. To me, this represents the most likely path towards the realisation of Nietzsche's `}<a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Last_man"
          }}>{`last man`}</a>{`. I highly encourage reading `}<em parentName="li">{`Brave New World`}</em>{` so you can recognise its uncannily accurate predictions regarding the trajectory of modern society. Also see his `}<a parentName="li" {...{
            "href": "https://alex-sk.jimdofree.com/aldous-huxley-brave-new-world-foreword/"
          }}>{`foreward`}</a>{` to the second edition in 1946.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Thus_Spoke_Zarathustra"
          }}>{`Thus Spoke Zarathustra (Nietzsche 1883-1885)`}</a>{`: Nietzsche's magnum opus that details all the major themes of his philosophy. Besides the sharp content, I enjoy reading this book just to indulge in the elegance of its prose; there are many passages that are beautiful beyond belief, even after translation from the original German. A tough read that will take many passes to understand but the rewards are well worth the effort.`}</li>
      </ul>
      <h2>{`Computer Science`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/papers-we-love/papers-we-love/blob/master/design/out-of-the-tar-pit.pdf"
          }}>{`Out of the Tar Pit (Moseley & Marks 2006)`}</a>{`: A must-read paper on complexity in systems arguing that most of the complexity in software is `}<em parentName="li">{`accidental`}</em>{`, rather than `}<em parentName="li">{`essential`}</em>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.infoq.com/presentations/clojure-core-async/"
          }}>{`Clojure core.async (Hickey 2013)`}</a>{`: Rich Hickey discusses the design of `}<inlineCode parentName="li">{`core.async`}</inlineCode>{`, Clojure(script)'s standard library for asynchronous programming. He contrasts several approaches for writing async code, including callbacks, async/await, and communicating sequential processes (CSP).`}</li>
      </ul>
      <h2>{`Documentaries`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://youtu.be/WXuK6gekU1Y"
          }}>{`AlphaGo - The Movie (2017)`}</a>{`: This documentary really moved me. I had always appreciated the game of Go but I now have an even more profound respect for the tradition behind the game. And I have an even deeper respect for Lee Sedol for fighting so fiercely to come back for that win after three straight losses—just unbelievable and a testament to the ingenuity of the human mind and the strength of the human spirit even when pitted against superhuman computing power.`}</li>
      </ul>
      <h2>{`Anime`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Berserk_(1997_TV_series)"
          }}>{`Berserk`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Fullmetal_Alchemist:_Brotherhood"
          }}>{`Fullmetal Alchemist: Brotherhood`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Hunter_%C3%97_Hunter_(2011_TV_series)"
          }}>{`Hunter x Hunter`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Steins;Gate_(TV_series)"
          }}>{`Steins;Gate`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Monster_(manga)"
          }}>{`Monster`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Re:Zero_%E2%88%92_Starting_Life_in_Another_World"
          }}>{`Re:Zero`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Gurren_Lagann"
          }}>{`Gurenn Lagann`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Neon_Genesis_Evangelion"
          }}>{`Neon Genesis Evangelion`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Naruto"
          }}>{`Naruto`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Bleach_(TV_series)"
          }}>{`Bleach`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Dragon_Ball_Z"
          }}>{`Dragon Ball Z`}</a></li>
      </ul>
    </TextPageLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      