import React from "react"
import SEO from "./seo"

// A layout used for textual (typically Markdown) root-level pages like About.
function TextPageLayout({ title, children }) {
  return (
    <>
      <SEO title={title} />
      <article style={{ maxWidth: "32em" }}>{children}</article>
    </>
  )
}

export default TextPageLayout
